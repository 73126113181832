import { ComIbmCommerceEmailsubscription } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class SubmitEmail<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;
	traceDetails: string | undefined;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
		this.traceDetails = process.env.TRACE_DETAILS?.trim();
	}

	/**
 * @description submit email
 *
 * @tags submit-email
 * @name JjkEmailGetEmailSubscriptionCreate
 * @summary submit email
 * @request POST:/store/{storeId}/jjkEmail/getEmailSubscription
 * @secure
 * @response `200` `{
  emailAddress?: string,
  status?: string,

}` The requested completed successfully.
 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
 * @response `500` `void` Internal server error. For details, see the server log files.
 */
	jjkEmailGetEmailSubscriptionCreate = (
		storeId: string,
		data: ComIbmCommerceEmailsubscription,
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (
			loggerCan('trace') &&
			(!this.traceDetails || this.traceDetails.includes('jjkEmailGetEmailSubscriptionCreate'))
		) {
			const paramsLogger = logger.child({
				params,
				query: null ?? {},
				body: data ?? {},
				methodName: 'jjkEmailGetEmailSubscriptionCreate',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<
			{
				emailAddress?: string;
				status?: string;
			},
			void
		>({
			path: `/store/${storeId}/jjkEmail/getEmailSubscription`,
			method: 'POST',
			body: data,
			secure: true,
			type: params.type ?? ContentType.Json,
			format: params.format ?? 'json',
			...params,
		});
	};
}
