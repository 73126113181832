import { HttpClient, RequestParams } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class LlpbatchmanagementById<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;
	traceDetails: string | undefined;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
		this.traceDetails = process.env.TRACE_DETAILS?.trim();
	}

	/**
 * @description get verson count by its Id
 *
 * @tags llpbatchmanagement-by-id
 * @name JjkproductLlpbatchmanagementDetail
 * @summary Get verson count by its Id
 * @request GET:/store/{storeId}/jjkproduct/llpbatchmanagement/{productId}
 * @secure
 * @response `200` `{
  versionType?: any,

}` The requested completed successfully.
 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
 * @response `500` `void` Internal server error. For details, see the server log files.
 */
	jjkproductLlpbatchmanagementDetail = (
		storeId: string,
		productId: string,
		params: RequestParams = {}
	) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (
			loggerCan('trace') &&
			(!this.traceDetails || this.traceDetails.includes('jjkproductLlpbatchmanagementDetail'))
		) {
			const paramsLogger = logger.child({
				params,
				query: null ?? {},
				body: null ?? {},
				methodName: 'jjkproductLlpbatchmanagementDetail',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<
			{
				versionType?: any;
			},
			void
		>({
			path: `/store/${storeId}/jjkproduct/llpbatchmanagement/${productId}`,
			method: 'GET',
			secure: true,
			format: params.format ?? 'json',
			...params,
		});
	};
}
