import { ContentType, HttpClient, RequestParams } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class RequestQuote<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;
	traceDetails: string | undefined;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
		this.traceDetails = process.env.TRACE_DETAILS?.trim();
	}

	/**
	 * @description Request quote form for bulk order
	 *
	 * @tags request-quote
	 * @name JjkQuoteCreate
	 * @summary Request Quote form
	 * @request POST:/store/{storeId}/jjkQuote
	 * @secure
	 * @response `200` `any` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	jjkQuoteCreate = (storeId: string, data: any, params: RequestParams = {}) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (
			loggerCan('trace') &&
			(!this.traceDetails || this.traceDetails.includes('jjkQuoteCreate'))
		) {
			const paramsLogger = logger.child({
				params,
				query: null ?? {},
				body: data ?? {},
				methodName: 'jjkQuoteCreate',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<any, void>({
			path: `/store/${storeId}/jjkQuote`,
			method: 'POST',
			body: data,
			secure: true,
			type: params.type ?? ContentType.Json,
			format: params.format ?? 'json',
			...params,
		});
	};
}
