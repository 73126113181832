import { ContentType, HttpClient, RequestParams } from './http-client';

import { loggerCan } from '@/data/utils/loggerUtil';
import { logger } from '@/logging/logger';

export class ProductSchema<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;
	traceDetails: string | undefined;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
		this.traceDetails = process.env.TRACE_DETAILS?.trim();
	}

	/**
	 * @description Provides RESTful services to provide product schema in json-ld format
	 *
	 * @tags product-schema
	 * @name MicroDataProductCreate
	 * @summary Product details schema
	 * @request POST:/store/{storeId}/microData/product
	 * @secure
	 * @response `200` `any` The requested completed successfully.
	 * @response `400` `void` Bad request. The request could not be understood by the server due to malformed syntax.
	 * @response `500` `void` Internal server error. For details, see the server log files.
	 */
	microDataProductCreate = (storeId: string, data: any, params: RequestParams = {}) => {
		const { _requestId: requestId } = params as any;
		delete (params as any)._requestId;

		if (
			loggerCan('trace') &&
			(!this.traceDetails || this.traceDetails.includes('microDataProductCreate'))
		) {
			const paramsLogger = logger.child({
				params,
				query: null ?? {},
				body: data ?? {},
				methodName: 'microDataProductCreate',
				requestId,
			});
			paramsLogger.trace('API request parameters');
		}
		return this.http.request<any, void>({
			path: `/store/${storeId}/microData/product`,
			method: 'POST',
			body: data,
			secure: true,
			type: params.type ?? ContentType.Json,
			format: params.format ?? 'json',
			...params,
		});
	};
}
